<template>
  <v-container>
      <BaseBreadcrumb :title="title" :breadcrumbs="breadcrumbs" />
      <base-card :heading="title + ' - Aluno'" class="mb-6">
          <v-row class="pa-5">
              <v-col v-for="material, index in studentsMaterials" :key="index" cols="12" md="4" sm="12">
                  <v-card class="pa-5" width="500">
                      <v-card-title>
                          <span class="!text-sm">{{ material.curricularUnit }}</span>
                      </v-card-title>
                      <v-card-text>
                          <span>{{ material.title}}</span>
                      </v-card-text>
                      <v-card-actions class="flex justify-end items-center">
                          <v-btn color="primary" text @click="openMaterial(material.link)">Ver material</v-btn>
                      </v-card-actions>
                  </v-card>
              </v-col>
          </v-row>
      </base-card>
      <base-card :heading="title + ' -  Professor'" class="mb-6">
      <v-row class="pa-5">
          <v-col v-for="material, index in teacherMaterials" :key="index" cols="12" md="4" sm="12">
              <v-card class="pa-5" width="500">
                  <v-card-title>
                      <span class="!text-sm">{{ material.curricularUnit }}</span>
                  </v-card-title>
                  <v-card-text>
                      <span>{{ material.title}}</span>
                  </v-card-text>
                  <v-card-actions class="flex justify-end items-center">
                      <v-btn color="primary" text @click="openMaterial(material.link)">Ver material</v-btn>
                  </v-card-actions>
              </v-card>
          </v-col>
      </v-row>
  </base-card>
  </v-container>
</template>

<script>

export default {
  name: 'SupportMaterialView',
  data: () => ({
    organizationId: localStorage.getItem('ACTIVE_ORGANIZATION'),
    title: 'Materiais de apoio',
    breadcrumbs: [
      { text: 'Início', disabled: false, to: '/' },
      { text: 'Materiais de apoio', disabled: false, to: '/support-material' }
    ],
    studentsMaterials: [
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 1º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_1ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 2º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_2ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 3º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_3ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 4º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_4ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 5º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_5ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 6º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_6ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 7º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_7ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 8º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_8ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 9º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_9ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 1º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_1ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 2º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_2ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 3º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_3ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 4º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_4ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 5º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_5ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 6º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_6ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 7º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_7ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 8º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_8ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 9º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mt_9ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - CIÊNCIAS HUMANAS - 9º ANO',
        curricularUnit: 'Ciências Humanas',
        link: 'https://issuu.com/editoralt/docs/ch_9ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - CIÊNCIAS NATUREZA - 9º ANO',
        curricularUnit: 'Ciências da Natureza',
        link: 'https://issuu.com/editoralt/docs/cn_9ano_aluno?fr=xKAE9_zU1NQ'
      },
      {
        title: 'Jogos de Língua Portuguesa e Matemática',
        curricularUnit: 'Jogos SABE Brasil',
        link: 'https://sabebrasil.gameseditoralt.com.br/'
      }
    ],
    teacherMaterials: [
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 1º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_1ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 2º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_2ano_manual_professor_2?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 3º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_3ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 4º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_4ano_professor_miolo_2?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 5º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_5ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 6º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_6ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 7º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_7ano_professor_miolo_?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 8º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_8ano_miolo_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - LÍNGUA PORTUGUESA - 9º ANO',
        curricularUnit: 'Língua Portuguesa',
        link: 'https://issuu.com/editoralt/docs/lp_9ano_manual_?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 1º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mat_1ano_manual_miolo?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 2º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mat_2ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 3º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mat_3ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 4º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mat_4ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 5º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mat_5ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 6º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mat_6ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 7º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mat_7ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 8º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mat_8ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - MATEMÁTICA - 9º ANO',
        curricularUnit: 'Matemática',
        link: 'https://issuu.com/editoralt/docs/mat_9ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - CIÊNCIAS HUMANAS - 9º ANO',
        curricularUnit: 'Ciências Humanas',
        link: 'https://issuu.com/editoralt/docs/ch_9ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'SABE BRASIL - CIÊNCIAS NATUREZA - 9º ANO',
        curricularUnit: 'Ciências da Natureza',
        link: 'https://issuu.com/editoralt/docs/cn_9ano_manual?fr=xKAE9_zU1NQ'
      },
      {
        title: 'Jogos de Língua Portuguesa e Matemática',
        curricularUnit: 'Jogos SABE Brasil',
        link: 'https://sabebrasil.gameseditoralt.com.br/'
      }
    ]
  }),
  methods: {
    openMaterial (link) {
      window.open(link, '_blank')
    }
  }
}
</script>
